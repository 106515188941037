
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        














































































.faq-item,
[class*='faq-item--'] {
  @include bg-color;

  border-radius: 0.5rem;

  .is-dark & {
    background-color: rgba($c-gallery, 0.1);

    @include mq(l) {
      background-color: transparent;
      border-bottom: 1px solid $c-anthracite-light;
      border-radius: 0;
    }
  }

  .is-light & {
    background-color: rgba($c-gallery, 0.5) !important;

    @include mq(l) {
      background-color: transparent;
      border-bottom: 1px solid $c-blue-light-pale;
      border-radius: 0;
    }
  }
}

.faq-item__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing $spacing * 2;
  cursor: pointer;

  .is-dark & {
    padding: $spacing;
    color: $white;

    @include mq(l) {
      padding: $spacing 0;
    }
  }

  .is-light & {
    padding: $spacing;
    color: $c-midnight-darker;

    @include mq(l) {
      padding: 22px 36px;
    }
  }
}

.faq-item__title__icon {
  flex-shrink: 0;
  margin-left: $spacing * 0.5;
  fill: $c-pink-medium;
  pointer-events: none;
  transition: transform 0.3s ease-out;

  .is-dark & {
    fill: $white;
  }

  .is-light & {
    fill: $c-midnight-darker;
  }

  .faq-item__title.is-open & {
    transform: rotate(180deg);
  }
}

.faq-item__excerpt-outer {
  position: relative;
  overflow: hidden;
  height: 0;
}

.faq-item__excerpt {
  margin: 0;
  padding: 0 $spacing * 2 $spacing;

  .is-dark & {
    color: $white;
  }

  .is-light & {
    color: $c-midnight-darker;
  }

  @include mq(m) {
    padding-right: col(1);
  }

  p,
  ::v-deep p {
    margin: 0;
  }

  div + div {
    margin-top: $spacing;
  }
}
