
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


























.header,
[class*='header--'] {
  position: relative;
}

.header__logo {
  margin-bottom: 2rem;
  height: 4rem;
  width: auto;
}
