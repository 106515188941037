
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
















































































































































































































































































































































































.promo-hero {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 25% 0 10%;
  background-color: $c-midnight-darker;

  &.hidden > * {
    opacity: 0;
  }

  ::v-deep {
    .countdown {
      width: 28rem;
      padding: 0;
      background-color: transparent;
      border: 1px solid $white;
      border-radius: 10px;

      @include mq(m) {
        width: 32rem;
      }
    }

    .countdown__table-outer {
      padding: 2.4rem 1.5rem 1.1rem;
      color: $white;
      /* stylelint-disable-next-line declaration-no-important */
      border: 0 !important;

      @include mq(l) {
        padding: 3.4rem 1.5rem 2.1rem;
      }
    }

    .countdown__table__row__column {
      font-size: 3rem;

      @include mq(m) {
        font-size: 3.6rem;
      }
    }

    .countdown__table__row__column__label {
      margin-top: 0;
      font-size: 1.1rem;

      @include mq(m) {
        font-size: 1.3rem;
      }
    }
  }

  @include mq(l) {
    padding: $spacing * 10 0 $spacing * 2;
  }
}

.promo-hero__markee {
  @include center-xy;

  top: 28rem;
  opacity: 0.2;

  .promo & {
    display: none;
  }

  @include mq(l) {
    top: 58rem;
  }
}

.promo-hero__content {
  text-align: center;
}

.promo-hero__picture-outer {
  @include get-all-space;

  z-index: -1;
  transform: rotate(180deg);

  .homepage & {
    display: none;
  }
}

.promo-hero__picture {
  @include get-all-space;

  right: auto;
  left: 50%;
  transform: translateX(-50%);
  max-width: 160rem;

  img,
  source {
    @include image-fit($position: 0 90%);

    z-index: -1;
  }

  @include mq(l) {
    img,
    source {
      @include image-fit($position: center);
    }
  }

  @include mq(xxl) {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      display: block;
      width: 12rem;
      height: 100%;
    }

    &::before {
      left: 0;
      /* stylelint-disable-next-line declaration-colon-newline-after */
      background-image: linear-gradient(
        to left,
        rgba($c-promo, 0),
        rgba($c-promo, 1)
      );
    }

    &::after {
      right: 0;
      /* stylelint-disable-next-line declaration-colon-newline-after */
      background-image: linear-gradient(
        to right,
        rgba($c-promo, 0),
        rgba($c-promo, 1)
      );
    }
  }
}

.promo-hero__label-outer {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  font-family: $ff-alt;

  .voo-app--be & {
    justify-content: center;
  }

  .voo-app--business & {
    flex-direction: column;
  }
}

[class*='promo-hero__label__title'] {
  @extend %fw-black;

  padding: 0.6rem 1rem;

  @include mq(l) {
    font-size: 1.4rem;
  }
}

[class*='promo-hero__label__subtitle'] {
  @extend %fw-black;

  font-size: 1rem;
  transform: translateY(-0.5rem);

  @include mq(l) {
    font-size: 1.2rem;
  }
}

.promo-hero__logo {
  position: relative;
  margin-top: $spacing * -1;

  img {
    display: block; // Remove unwanted y spacing caused by inline block
    max-width: 100%;
    margin: 2rem auto 0;

    @include mq(s) {
      max-width: 66rem;
    }
  }

  @include mq(l) {
    margin-top: $spacing * -2;
  }
}

.promo-hero__title {
  margin: 2rem auto 0;
  color: $white;

  ::v-deep small {
    font-size: 50%;
  }

  @include mq($until: m) {
    max-width: 10em;
    font-size: 3.8rem;
    line-height: 1;
  }
}

.promo-hero__subtitle {
  max-width: 72rem;
  margin: 1.4rem auto $spacing * 1.5;
  margin-inline: auto;
  color: $white;
  font-size: 1.5rem;

  @include mq(l) {
    font-size: 3rem;
  }
}

.promo-hero__excerpt {
  color: $white;
  font-size: 2rem;
  line-height: 3.2rem;

  @include mq(l) {
    margin-bottom: 0;
  }
}

.promo-hero__label {
  @extend %fw-black;

  max-width: 68rem;
  margin: 0 auto;
  color: $white;
  font-size: 1.4rem;

  @include mq(l) {
    margin-top: $spacing * -2;
  }
}

.promo-hero__countdown {
  margin: $spacing * 2 auto $spacing / 2;
}

.promo-hero__speedlines {
  @include center-xy;

  width: 100%;
}

.hero__scroll-outer {
  position: absolute;
  z-index: 99;
  right: $spacing * 2.5;
  bottom: $spacing * 4;
  display: none;

  body:not([data-template='homepage']) & {
    display: none;
  }

  @include mq(l) {
    display: block;
  }
}

.hero__scroll {
  @extend %text-uppercase;
  @extend %fw-black;

  display: flex;
  align-items: center;
  padding: $spacing / 2 $spacing;
  font-family: $ff-alt;
  font-size: 1.1rem;
  letter-spacing: 1px;
  background-color: $white;
  cursor: pointer;
  border-radius: 3rem;
  transform: rotate(-90deg);
  transition: background-color 0.2s $ease-out-quad;

  &:hover {
    /* stylelint-disable-next-line scss/no-global-function-names */
    background-color: darken($white, 5%);
  }
}

.hero__scroll__icon {
  width: 0.5em;
  margin-right: $spacing / 2;
  transform: rotate(180deg);
  animation: scroll 1s ease-out infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0) rotate(180deg);
  }

  50% {
    transform: translateX(-100%) rotate(180deg);
  }

  100% {
    transform: translateX(0) rotate(180deg);
  }
}
