
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


































































































































































































































.form-contact,
[class*='form-contact--'] {
}

.form-contact__inputs,
.form-contact__message {
  margin-bottom: $spacing * 1.5;
}

.form-contact__inputs__input {
  &:not(:last-child) {
    margin-bottom: $spacing;
  }
}

.form-contact__switcher {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing * 1.25;

  ::v-deep .switcher__option {
    width: 15rem;
    padding: $spacing;

    @include mq(s) {
      width: 18rem;
    }
  }

  ::v-deep .switcher__option__label {
    @extend %fw-medium;

    font-family: $ff-default;
    text-transform: none;
  }

  @include mq($until: s) {
    ::v-deep .switcher {
      width: 100%;
    }

    ::v-deep .switcher__option {
      width: 50%;
      padding: $spacing;
    }
  }

  @include mq(m) {
    ::v-deep .switcher__option__label {
      font-size: 1.4rem;
    }
  }
}

.form-contact__switcher__label {
  @extend %fw-bold;

  width: 14rem;
  margin-bottom: $spacing * 0.5;
  font-family: $ff-alt;

  white-space: nowrap;
}

.form-contact__message {
  ::v-deep textarea {
    min-height: 20rem;
  }
}
