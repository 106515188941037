
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        








































































































/* stylelint-disable declaration-no-important */
.smartphone-section {
  background-color: $white;
}

.smartphone-section-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.smartphone-section__title {
  // prettier-ignore
  @include fluid(font-size, (s: 48px, l: 52px));

  margin-bottom: 0;

  @include mq($until: l) {
    text-align: center;
  }
}

.smartphone-section__subtitle {
  // prettier-ignore
  @include fluid(font-size, (s: 18px, l: 24px));

  margin-top: $spacing;
  font-weight: 500;

  @include mq($until: l) {
    text-align: center;
  }
}
