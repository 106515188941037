
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
































.channels-extra,
[class*='channels-extra--'] {
  position: relative;
}

.channels-extra-inner {
  @extend %list-nostyle;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;

  @include mq('l') {
    grid-template-columns: repeat(5, 1fr);
  }
}

.channels-extra__item {
  aspect-ratio: 1;
  padding: 1rem;
  background-color: $c-light-gray;
  display: flex;
  justify-content: center;
  align-items: center;

  &.is-disabled {
    display: none;
  }

  .logo {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  @include mq('l') {
    padding: 1.5rem;
  }
}
