
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        












































































































































































.promo-options {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.promo-options__speedlines {
  @include get-all-space;
  @include center-xy;

  z-index: -1;

  .speedlines__item,
  [class*='speedlines__item--'] {
    @include mq($until: m) {
      opacity: 0;
    }
  }
}

.promo-options__title {
  @extend %text-center;

  margin: 0;
  color: $white;
}

.promo-options__subtitle {
  @extend %text-center;

  margin: 0;
  color: $white;
}

.promo-options__cards {
  width: 100%;
}

pre {
  background-color: $white;
}

// CARD
.promo-options-card,
[class*='promo-options-card--'] {
  @extend %box-shadow;

  scroll-snap-align: start;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  max-width: 35rem;
  height: auto;
  min-height: 51rem;
  margin: 0 0 $spacing * 2.5;
  color: $white;
  border-radius: 1rem;

  &::before {
    @include get-all-space;

    z-index: -1;
    content: '';
    display: block;
    background-color: rgba($white, 0.06);
    backdrop-filter: blur(15px);
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include mq($until: m) {
    width: 100%;
    min-height: 53rem;
  }

  @include mq(m) {
    margin: 0 $spacing $spacing * 2.5;
  }

  @include mq(l) {
    min-height: 55rem;
    margin: 0 $spacing / 2;
  }

  @include mq(xl) {
    margin: 0 1%;
  }
}

.promo-options-card-inner {
  width: 100%;
  padding: $spacing * 1.5;

  .blog-archive &,
  .blog-single & {
    text-align: left;
  }

  @include mq(m) {
    padding: $spacing * 1.5;
  }

  @include mq(l) {
    padding: $spacing * 2 $spacing * 1.5;
  }

  @include mq(xl) {
    padding: $spacing * 2;
  }
}

.promo-options-card__picture-outer {
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  width: 100%;
  height: 22rem;

  &.darkened::before {
    @include get-all-space;

    content: '';
    z-index: 1;
    background: rgba($black, 0.5);
  }
}

.promo-options-card__picture {
  @include image-fit;

  background-color: $c-gray-lightest;
}

.promo-options-card__bg {
  position: absolute;
  right: -3rem;
  bottom: -3rem;
  height: 70%;
}

.promo-options-card__header {
  position: relative;
  flex-shrink: 0;
  margin-bottom: $spacing * 0.75;
}

.promo-options-card__header__category {
  @extend %box-shadow;
  @extend %text-uppercase;

  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  padding: $spacing / 2 $spacing;
  color: $c-anthracite-light;
  font-size: 1.4rem;
  font-weight: 700;
  background-color: $white;
  border-radius: 1rem;
  transform: translateY(-150%);
}

.promo-options-card__title {
  width: 100%;
  margin: 0 0 $spacing * 0.5;
  font-size: 1.8rem;

  @include mq(m) {
    font-size: 2.6rem;
  }
}

.promo-options-card__text {
  @extend %fw-light;

  width: 100%;
  margin: 0 0 $spacing;
  font-size: 1.6rem;
  line-height: 1.5;

  ::v-deep {
    p {
      margin: 0;
    }
  }

  @include mq(m) {
    font-size: 1.8rem;
    line-height: 1.6;
  }
}

.promo-options-card__cta,
[class*='promo-options-card__cta--'] {
  margin-top: auto;

  .promo-options-card:hover & {
    color: $c-blue-dark;

    &::before {
      transform-origin: left;
      transform: scaleX(1);
    }
  }

  &[class*='--full'] {
    @include get-all-space;
  }
}

.promo-options-card__tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: $spacing * 2;
  padding-top: $spacing;
  border-top: 1px solid $c-gray-lightest;
}

.promo-options-card__tag {
  @extend %fw-bold;
  @extend %text-uppercase;

  margin: 0 $spacing / 2 $spacing / 2 0;
  padding: $spacing / 2 $spacing;
  color: $c-gray;
  font-family: $ff-alt;
  font-size: 1.2rem;
  text-decoration: none;
  border: 1px solid $c-gray-lightest;
  border-radius: 0.5rem;
}

.promo-options-card__price {
  justify-content: flex-start;
}

.promo-options-card__price__title {
  @extend %fw-bold;

  font-family: $ff-alt;

  color: $c-mandarine;
  font-size: 2.6rem;
}

.promo-options-card__price__subtitle {
  font-size: 1.4rem;
}

.promo-options-card__price__discount {
  display: flex;
}

.promo-options-card__original {
  position: relative;
  display: inline-flex;
  align-items: center;

  * {
    /* stylelint-disable-next-line declaration-no-important */
    color: $white !important;
    /* stylelint-disable-next-line declaration-no-important */
    font-size: 1.6rem !important;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    height: 2px;
    transform: translateY(-50%);
    background-color: $white;
    border-radius: 6px;
    opacity: 0.7;
  }

  .is-original & {
    display: none;
  }
}

.promo-options-card__price__main,
.promo-options-card__price__stack {
  color: $c-mandarine;
}

.promo-options-card__price__main {
  @extend %fw-bold;

  font-family: $ff-alt;
  font-size: 2.6rem;
  text-align: right;

  @include mq(m) {
    line-height: 1;
  }
}

.promo-options-card__price__stack__decimal {
  @extend %fw-medium;

  font-family: $ff-alt;
  font-size: 1.8rem;
}

.promo-options-card__link {
  margin-top: $spacing;
}
