
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

















.hero,
[class*='hero--'] {
  position: relative;
  width: 100%;
  height: 25rem;

  @include mq('s') {
    height: 35rem;
  }
}

.hero__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero__logo {
  position: absolute;
  bottom: 2rem;
  left: 1.5rem;
  width: auto;
  height: 4rem;

  @include mq('s') {
    bottom: 3rem;
    left: 6rem;
    height: 5rem;
  }
}
