
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
































.g-channels,
[class*='g-channels--'] {
  position: relative;
}

.g-channels__title {
  margin-top: 0;
  margin-bottom: 4rem;
}
