
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        








































































































































































































































































































































































































































































$top-bts: 7rem;

.promo-card,
[class*='promo-card--'] {
  --corner-speedline-width: 110%;
  --corner-speedline-height: 250%;
  --corner-speedline-offset: -30%;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  background: $c-promo-card;
  border-radius: 20px;
  box-shadow: 0 30px 90px $c-promo-shadow;

  &.unavailable {
    &::before {
      @include get-all-space;

      content: '';
      z-index: 3;
      background: rgba($c-blue-dark, 0.7);
      border-radius: 2rem;
    }
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 10%;
    left: 0;
    display: block;
    width: 50%;
    height: 0;
    padding-bottom: 50%;
    background: radial-gradient($c-promo-shadow, transparent 70%);
    opacity: 0.5;
  }

  &.type-bundle {
    justify-content: space-between;
  }

  &.type-hod {
    @include mq(m, xl) {
      justify-content: space-between;
    }
  }

  &.type-hardware {
    overflow: unset;

    &.bts--magenta {
      ::v-deep .label--pink {
        background-color: $c-pink-medium !important;
      }
    }

    &.bts--white {
      ::v-deep .label--pink {
        color: $c-pink-medium;
        background-color: $white !important;
      }
    }

    &.bts--orange {
      ::v-deep .label--pink {
        background-color: $c-mandarine !important;
      }
    }

    .hardware-counter {
      top: 40%;
      width: fit-content;

      ::v-deep span {
        /* stylelint-disable-next-line declaration-no-important */
        display: flex !important;
        /* stylelint-disable-next-line declaration-no-important */
        flex-direction: column !important;

        /* stylelint-disable-next-line max-nesting-depth */
        strong {
          font-size: 4.6rem;
          font-weight: 900;
          line-height: 4.6rem;

          /* stylelint-disable-next-line max-nesting-depth */
          @include mq(l) {
            font-size: 7.2rem;
            line-height: 6.4rem;
          }
        }
      }

      @include mq($until: s) {
        top: 4%;
        /* stylelint-disable-next-line declaration-no-important */
        left: 5% !important;
        /* stylelint-disable-next-line declaration-no-important */
        transform: none !important;
      }
    }

    &.picture--left {
      .hardware-counter {
        left: -2.5rem;
      }
    }

    &[class*='picture--'] {
      @include mq($until: l) {
        padding-top: 6rem;

        /* stylelint-disable-next-line max-nesting-depth */
        .hardware-counter {
          position: absolute;
          /* stylelint-disable-next-line declaration-no-important */
          top: 4rem !important;
          left: 0;
        }
      }
    }

    &.picture--center {
      .hardware-counter {
        /* stylelint-disable-next-line declaration-no-important */
        left: 50% !important;
        /* stylelint-disable-next-line declaration-no-important */
        transform: translate(-50%, -50%) !important;

        /* stylelint-disable-next-line max-nesting-depth */
        @include mq($until: l) {
          transform: translateX(-50%) !important;
        }
      }
    }

    &.picture--right {
      .hardware-counter {
        right: -2.5rem;
        left: inherit;

        /* stylelint-disable-next-line max-nesting-depth */
        @include mq($until: l) {
          right: 2rem;
          left: inherit !important;
        }
      }
    }

    @include mq($until: l) {
      .promo-card__picture-outer {
        width: inherit;
        height: 28rem;
      }
    }
  }

  &.type-bts {
    position: relative;
    justify-content: flex-start;
    padding-top: 0;

    &::after {
      position: absolute;
      top: -19rem;
      left: 50%;
      display: block;
      width: 70rem;
      height: 30rem;
      background-color: $c-pink-medium;
      content: '';
      border-radius: 25rem;
      transform: translateX(-34%) rotate(-50deg) scale(0.9);

      .has-counter & {
        top: -17rem;
      }

      @include mq(l) {
        top: -16.5rem;
        right: -32rem;
        left: inherit;
        transform: rotate(-50deg) scale(0.55);
      }

      @include mq(xl) {
        top: -19rem;
        transform: rotate(-50deg) scale(0.9);
      }

      @include mq(xxl) {
        top: -16rem;
        right: -31rem;
        padding-top: 4rem;
        transform: rotate(-50deg) scale(1.1);
      }
    }

    &.bts--magenta::after {
      background-color: $c-pink-medium;
    }

    &.bts--white::after {
      background-color: $white;
    }

    &.bts--orange::after {
      background-color: $c-mandarine;
    }

    &.has-counter {
      &::after {
        @include mq($until: l) {
          top: -17rem;
        }
      }
    }

    @include mq($until: l) {
      padding-top: 22rem;
    }

    @include mq(xxl) {
      padding-top: 4rem;
    }
  }

  @include mq(l) {
    --corner-speedline-width: 28rem;
    --corner-speedline-height: calc(var(--corner-speedline-width) * 2);
    --corner-speedline-offset: -10rem;

    padding-top: 65%;

    &.is-highlight,
    &.type-hod,
    &.type-full-width {
      padding-top: 0;
    }

    &.is-highlight {
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }

    &.type-bundle {
      justify-content: flex-end;
    }
  }

  @include mq(xl) {
    --corner-speedline-width: 35rem;

    padding-top: 45%;
  }

  @include mq(xxl) {
    --corner-speedline-width: 40rem;
  }
}

.promo-card__nav,
.promo-card__picture-outer,
.promo-card__content {
  position: relative;
  z-index: 2;
}

.promo-card__picture-outer {
  position: relative;
  flex-shrink: 0;
  align-self: flex-end;
  width: 60%;
  max-width: 40rem;
  height: 20rem;
  margin: 0 auto;
  padding: 1rem;

  &::after {
    @include center-xy;

    content: '';
    z-index: -5;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background: radial-gradient($c-promo-shadow, transparent 70%);
    opacity: 0.7;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .type-free & {
    &::after {
      content: none;
    }
  }

  .type-hod &,
  .type-full-width & {
    width: 100%;
    max-width: none;
    height: fit-content;
    margin: 0;
    padding: 0;

    img {
      height: auto;
      object-fit: unset;
    }
  }

  @include mq(s) {
    height: 45%;

    .is-highlight & {
      height: 40rem;

      &::after {
        width: 110%;
      }
    }
  }

  @include mq(l) {
    position: absolute;
    top: 0;
    left: 50%;
    height: 60%;
    margin: 0;

    .promo-card:not(.type-full-width) > & {
      transform: translateX(-50%);
    }

    .bts & {
      transform: translateX(-50%);
    }

    &::after {
      transform: translate(-65%, -50%);
    }

    .is-highlight & {
      position: static;
      max-width: 60rem;
      height: 50rem;
    }

    .type-free & {
      // width: 80%;
      max-width: none;
      // height: 60%;
    }

    .type-hod &,
    .type-full-width & {
      position: static;
    }
  }

  // @include mq(xl) {
  //   .type-free & {
  //     height: 75%;
  //   }
  // }
}

.promo-card__picture__label {
  @include center-x;

  bottom: 0;

  @include mq(l) {
    bottom: 20%;
  }
}

.promo-card__price {
  @extend %fw-bold;

  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20rem;
  margin-left: auto;
  padding: $spacing;
  font-family: $ff-alt;
  font-size: 2rem;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: var(--corner-speedline-offset);
    display: block;
    width: var(--corner-speedline-width);
    height: var(--corner-speedline-height);
    background: $white;
    border-radius: 0 0 25rem 25rem;
    transform: translateY(-50%) rotate(33deg);
    transform-origin: center;
  }

  @include mq(l) {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 24rem;
    height: calc(calc(var(--corner-speedline-height) * 0.5) - #{$spacing * 2});
    padding-left: $spacing * 2;
  }

  @include mq(xl) {
    width: 30rem;
    padding-left: $spacing;
    font-size: 3.2rem;
  }

  @include mq(xxl) {
    width: 32rem;
    padding-left: 0;
  }
}

.promo-card__price__old {
  position: relative;
  display: inline-block;
  color: $c-blue-pale;
  line-height: 1;

  &::before {
    @include center-xy;

    content: '';
    position: absolute;
    z-index: 1;
    display: block;
    width: 110%;
    height: 0.2rem;
    background: $c-pink-medium;
    transform: translate(-50%, -50%) rotate(-45deg);
    transform-origin: center;
  }
}

.promo-card__price__new {
  @extend %fw-ultra;

  margin: $spacing * 0.5 0;
  color: $c-pink-medium;
  font-size: 4rem;
  line-height: 1;

  @include mq(l) {
    font-size: 8rem;
  }

  @include mq(xxl) {
    font-size: 12.6rem;
  }
}

.promo-card__price__text {
  ::v-deep p {
    margin: 0;
  }
}

.promo-card__content {
  flex-shrink: 0;
  padding: 0 2.5rem 2rem;

  @include mq(l) {
    padding: 0 4rem 4rem;

    .is-highlight & {
      flex-basis: 50%;
      padding: 8rem 5rem;
    }

    .type-hod & {
      padding-top: 3rem;
    }
  }
}

.promo-label {
  display: flex;
  gap: 1.5rem;
}

.promo-card__label {
  display: inline-block;
  margin-bottom: 1rem;
}

.promo-card__logo-outer {
  margin: 0 0 $spacing * 0.75;

  /* stylelint-disable-next-line no-descending-specificity */
  img {
    width: auto;
    max-height: 10rem;
  }
}

.promo-card__title,
.promo-card__subtitle {
  color: $white;
}

.promo-card__title {
  @extend %fw-ultra;

  margin: 0 0 0.5rem;
  font-family: $ff-alt;
  font-size: 2.4rem;
  line-height: 1.2;

  .type-hod &,
  .type-full-width & {
    font-weight: 700;
  }

  @include mq(l) {
    font-size: 3.2rem;

    .is-highlight & {
      width: auto;
      font-size: 7.2rem;
    }
  }

  @include mq(xl) {
    width: 70%;
  }
}

.promo-card__rgus {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $spacing;
  margin: $spacing 0;

  @include mq(l) {
    gap: $spacing * 1.5;
  }
}

.promo-card__rgus__item {
  position: relative;
  margin: 0;

  & + &::before {
    @extend %fw-bold;
    @include center-xy;

    content: '+';
    left: $spacing * -0.5;
    color: $white;
    font-family: $ff-alt;
  }

  .icon {
    display: block;
    width: 4rem;
    height: 2rem;
    fill: $white;

    &.mobile {
      width: 2rem;
    }
  }

  @include mq(l) {
    & + &::before {
      left: $spacing * -0.75;
      font-size: 2.2rem;
    }

    .icon {
      width: 7rem;
      height: 4rem;

      &.mobile {
        width: 4rem;
      }
    }
  }
}

.promo-card__subtitle {
  font-size: 1.3rem;

  i {
    font-style: normal;
  }

  .type-bts & {
    ::v-deep p {
      margin: 0;
    }

    @include mq(l) {
      height: 12rem;
    }
  }

  .type-hod & {
    @include mq(m, xl) {
      min-height: 9rem;
    }
  }

  @include mq(l) {
    font-size: 1.8rem;
  }
}

.promo-card__nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1.5rem;

  .is-highlight & {
    margin-top: $spacing;
  }

  @include mq(l) {
    margin-top: 3rem;

    .is-highlight & {
      margin-top: $spacing * 2;
    }
  }
}

.promo-card__nav__btn {
  margin: 1rem $spacing 1rem 0;
  padding: 1.5rem;

  &,
  ::v-deep .btn__link__title {
    font-size: 1.6rem;
    line-height: 1;

    @include mq(l) {
      font-size: 2rem;
    }
  }

  @include mq(l) {
    margin-right: $spacing * 2;
  }
}

.promo-card__speedlines-outer {
  @include get-all-space;

  z-index: 1;
  overflow: hidden;
  border-radius: 20px;
}

.promo-card__speedlines {
  @include get-all-space;

  width: 100%;

  .speedlines {
    @include get-all-space;
  }

  @include mq(l) {
    left: initial;
    width: 70%;

    .is-highlight & {
      right: initial;
      left: 0;
      width: 50%;
    }
  }
}

.promo-card__stock {
  display: none;

  .unavailable & {
    display: none;
  }
}

.promo-card__unavailable {
  @extend %text-center;
  @include center-xy;

  z-index: 4;
  display: none;
  padding: 2rem;
  color: $c-blue-dark;
  font-family: $ff-alt;
  font-size: 1.8rem;
  line-height: 1.2;
  text-align: center;
  background-color: $white;
  border-radius: 8px;

  .unavailable & {
    display: block;
  }

  strong {
    @extend %fw-black;

    display: block;
    white-space: nowrap;
  }

  .blue & {
    background: $c-light-blue;
  }

  .cyan & {
    background: $c-cyan-contrast;
  }

  .orange & {
    background: $c-orange;
  }

  .burgundy & {
    background: $c-burgundy;
  }

  .midnightblue & {
    background: $c-blue-dark;
  }

  .pink & {
    background: $c-pink-light;
  }
}

.promo-card__counter {
  &.is-desktop {
    @include center-y;

    left: 0;
    display: none;
    width: 17rem;

    ::v-deep [class*='label'] {
      font-weight: 500;
      text-transform: none;
      white-space: pre-wrap;

      @include mq(m) {
        line-height: 1.3;
      }
    }

    @include mq(s) {
      display: block;
    }

    @include mq(m) {
      top: 50%;
      width: 20rem;
      padding: 1rem 1.5rem;
    }

    @include mq(l) {
      width: 23rem;
    }

    @include mq(xl) {
      left: 5%;
    }
  }

  &.is-mobile {
    display: block;
    text-align: center;
    transform: translateY(-50%);

    ::v-deep [class*='label'] {
      padding: 0.5rem $spacing / 1.5 0.35rem;
      white-space: pre-wrap;
      border-radius: 3px;
    }

    @include mq(s) {
      display: none;
    }
  }

  ::v-deep span {
    .type-hardware & {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 12rem;
      height: 12rem;
      font-size: 1.4rem;
      /* stylelint-disable-next-line declaration-no-important */
      font-weight: 700 !important;
      text-align: center;
      /* stylelint-disable-next-line declaration-no-important */
      text-transform: uppercase !important;
      border-radius: 100% !important;

      @include mq(l) {
        width: 18rem;
        height: 18rem;
        font-size: 1.6rem;
      }
    }
  }
}

pre {
  background-color: $white;
}

.promo-card__savings {
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 30rem;
  min-height: 120px;
  padding: 1.5rem 4rem 1.5rem 0;
  transform-origin: top right;
  color: $white;

  // background-color: $c-pink-medium;
  border-radius: 20px;

  /* stylelint-disable-next-line no-descending-specificity */
  span {
    @extend %fw-ultra;

    // prettier-ignore
    @include fluid(font-size,(xxs: 14px,xxl: 33px,));

    font-family: $ff-alt;
    font-size: 2rem;
    line-height: 1;
    text-transform: uppercase;

    &.label {
      margin-top: 1.5rem;
      padding: 1rem 1.6rem;
      font-size: 13px !important;

      @include mq($until: l) {
        margin-top: 1rem;
        padding: 1rem;
        font-size: 11px !important;
      }
    }

    &:first-child {
      font-size: 10rem;

      @include mq($until: l) {
        font-size: 4rem;
      }
    }

    @include mq(l) {
      font-size: 3rem;
    }

    @include mq($until: l) {
      font-size: 1.4rem;
    }

    @include mq(xl) {
      font-size: 3rem;
    }
  }

  &.bts--white {
    span {
      color: $c-pink-medium;
    }

    .label {
      color: $white;
      background-color: $c-pink-medium;
    }
  }

  &.bts--orange {
    .label {
      color: $c-mandarine;
      background-color: $white;
    }
  }

  &.bts--magenta {
    .label {
      color: $c-pink-medium;
      background-color: $white;
    }
  }

  &.bts--magenta,
  &.bts--orange {
    span {
      color: $white;
    }
  }

  @include mq($until: l) {
    width: fit-content;
    margin-bottom: 2rem;
    margin-left: 2.5rem;
    border-radius: 10px;
    transform: scale(1);

    .type-bts & {
      position: absolute;
      top: 4rem;
      left: 50%;
      margin-left: 0;
      padding: 0;
      transform: translateX(-50%);

      span:first-child {
        font-size: 84px;
        line-height: 84px;
      }

      span:nth-child(2) {
        font-size: 28px;
        line-height: 32px;
      }
    }
  }

  @include mq(l) {
    position: absolute;
    top: 2rem;
    right: 2rem;
    min-height: 220px;
    transform: scale(0.5);
  }

  @include mq(xl) {
    top: 3rem;
    right: 3rem;
    transform: scale(0.7);
  }

  @include mq(xxl) {
    top: $top-bts;
    transform: scale(0.9);
    right: 4rem;
  }

  @include mq(xxxl) {
    max-width: inherit;
    padding: 1rem 3rem;
  }
}

.promo-card__subscription {
  position: relative;
  z-index: 10;
  margin-bottom: 8rem;
  margin-left: 4rem;

  span {
    @extend %fw-semi;

    color: $white;
    font-family: $ff-alt;
    font-size: 1.8rem !important;
  }

  div {
    display: flex;
    gap: 1rem;
    align-items: center;

    @include mq(xxxl) {
      gap: 2rem;
    }
  }

  img {
    filter: brightness(0) invert(1);
  }

  @include mq($until: l) {
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-left: 2.5rem;
    transform: scale(0.8);
    transform-origin: left;
  }

  @include mq(l, xl) {
    margin-bottom: 3rem;
  }

  @include mq(l) {
    max-width: 50%;
    margin-top: 3rem;
  }

  @include mq(xxl) {
    /* stylelint-disable-next-line declaration-no-important */
    margin-top: 4rem !important;
  }
}

.subscription {
  transform: scale(0.8);
  transform-origin: left;
  margin-bottom: 2rem;

  img {
    // max-width: 16rem;
    height: 7rem;

    @include mq(l) {
      height: 5rem;
    }

    @include mq(xxxl) {
      height: 7rem;
    }
  }

  @include mq(l) {
    margin-bottom: 1rem;

    span {
      font-size: 1.6rem;
    }
  }

  @include mq(xl) {
    transform: scale(1);
  }

  @include mq(xxxl) {
    span {
      font-size: 2.2rem;
    }
  }
}

.subscription__rgus {
  span {
    display: block;
    color: $white;
    font-size: 1rem;
    line-height: 1;
  }
}

.selection__products__figure {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  align-items: center;
  margin: 0;

  svg {
    fill: $white;
    width: 5rem;
    height: 3.1rem;

    @include mq(xl) {
      width: 6.5rem;
      height: 4rem;
    }

    @include mq(xxxl) {
      width: 8rem;
      height: 5rem;
    }
  }
}
