
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        




















































































$cards-accent: (
  pink: $c-pink-medium,
  orange: $c-mandarine,
  blue: #41bbc9,
  light-pink: $c-gift-color-a,
);

.promo-summer-hero {
  position: relative;
  display: flex;
  overflow: hidden;
  margin: $spacing * -3 0 $spacing * 2.5;
  font-family: $ff-alt;

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10rem;
    background: linear-gradient(
      180deg,
      rgb($white, 0) 0%,
      rgb($c-pink-medium, 15%) 100%
    );
  }
}

.promo-summer-hero__hero {
  display: none;
  width: 50%;
  height: 40rem;

  img {
    position: relative;
    z-index: 1;
    width: auto;
    height: 100%;
  }

  @include mq(m) {
    display: block;
  }
}

.promo-summer-hero__hero__splatter {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  fill: $c-pink-medium;
  transform: translate(-30%, 30%);

  [data-color='orange'] & {
    fill: $c-mandarine;
    transform: translate(-30%, 30%) rotate(-60deg);
  }

  [data-color='blue'] & {
    fill: $c-cyan-contrast;
    transform: translate(-30%, 30%) rotate(90deg);
  }

  [data-color='light-pink'] & {
    fill: $c-gift-color-a;
    transform: translate(-30%, 30%) rotate(-90deg);
  }
}

.promo-summer-hero__content {
  @extend %text-center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: $spacing * 2.5;
}

.promo-summer-hero__content__number {
  @extend %fw-black;

  position: relative;
  z-index: 1;
  font-size: 9.5rem;
  line-height: 0.9;
  text-shadow: 0.5rem 0.5rem 1rem rgba($black, 20%);

  &.small {
    font-size: 7rem;
  }

  @include mq(l) {
    font-size: 14rem;
    line-height: 13rem;

    &.small {
      font-size: 10rem;
      line-height: 9.5rem;
    }
  }
}

.promo-summer-hero__content__title {
  @extend %fw-black;
  @extend %text-uppercase;

  @include mq(l) {
    font-size: 3.5rem;
    line-height: 5rem;
  }
}

.promo-summer-hero__content__picture {
  max-width: 22rem;
  margin-top: $spacing * 0.5;
}

.promo-summer-hero__gift {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  width: 80rem;
  height: 80rem;
  fill: rgba($c-pink-medium, 10%);
  transform: rotate(10deg) translate(24%, -27%);
}
