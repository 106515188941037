
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

























































































.sticky-client,
[class*='sticky-client--'] {
  @extend %text-center;

  position: fixed;
  z-index: 200;
  bottom: 1.5rem;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacing / 2;
  background-color: $c-orange;
  border-radius: 8px;
  box-shadow: 0 0 4rem 0 rgba($black, 0.2);
  transform: translate(-50%, 0);
  transition: transform 0.2s ease-out;

  &.is-visible {
    transform: translate(-50%, 0);
  }

  @include mq(l) {
    z-index: 100;
    right: 3.5rem;
    bottom: 3.5rem;
    left: auto;
    max-width: 40rem;
    transition: transform $ease-out-quad 0.2s;
    transform: translateY(100%);

    &.is-visible {
      transform: translateY(0);

      &:hover {
        transform: scale(1.05) translateY(0) !important;
      }
    }
  }
}

.sticky-client__inner {
  position: relative;
  z-index: 1;
  max-width: 128rem;
  margin: 0 2rem;

  @include mq(l) {
    width: 100%;
    margin: 0;
  }
}

.sticky-client__content {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 128rem;
  margin: 0 auto;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include mq(l) {
    padding: 1rem $spacing;
  }
}

.sticky-client__link {
  @include get-all-space;

  z-index: 9;
}

.sticky-client__label,
.sticky-client__text,
.sticky-client__image {
  @include mq(l) {
    opacity: 0;
    transition: opacity;

    .is-visible & {
      opacity: 1;
    }
  }
}

.sticky-client__text {
  min-width: 6rem;

  @include mq($until: l) {
    display: flex;
    align-items: center;
    min-width: 20rem;
  }
}

.sticky-client__text__title {
  @extend %fw-bold;

  display: block;
  color: $white;
  font-family: $ff-alt;
  font-size: 1.4rem;
  line-height: 1.15;

  ::v-deep {
    strong {
      font-size: 2.4rem;
    }
  }
}

.sticky-client__text__subtitle {
  display: block;
  position: relative;
  margin: $spacing 0 0 0;
  padding: $spacing 0 0 0;
  color: $white;
  font-family: $ff-default;
  font-size: 1.5rem;
  line-height: 1.2;

  &::after {
    @include center-x;

    content: '';
    top: 0;
    display: block;
    width: 2rem;
    height: 1px;
    background-color: $white;
  }

  @include mq($until: l) {
    margin: 0 0 0 $spacing;
    padding: 0 0 0 $spacing;

    &::after {
      top: 50%;
      left: 0;
      width: 1px;
      height: 2rem;
      transform: translate(0, -50%);
    }
  }
}
