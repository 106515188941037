
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        






































































































































































.promo,
[class*='promo--'] {
  background-color: $c-midnight-darker;

  pre {
    background-color: $white;
  }
}

.promo__counter {
  display: none;
  padding: $spacing;
  background: $white;
}

.promo__hero {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 20% 0 10%;

  @include mq(l) {
    height: 42rem;
    padding: 17rem 0 0;
  }

  @include mq(xl) {
    height: 52rem;
    padding: 20rem 0 0;
  }
}

.promo__hero__content {
  text-align: center;
}

.promo__hero__picture-outer {
  @include get-all-space;

  z-index: -1;
  background-color: $c-promo;
}

.promo__hero__picture {
  @include get-all-space;

  right: auto;
  left: 50%;
  transform: translateX(-50%);
  max-width: 160rem;

  img,
  source {
    @include image-fit($position: 0 90%);

    z-index: -1;
  }

  @include mq(l) {
    img,
    source {
      @include image-fit($position: center);
    }
  }

  @include mq(xxl) {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      display: block;
      width: 12rem;
      height: 100%;
    }

    &::before {
      left: 0;
      background-image: linear-gradient(
        to left,
        rgba($c-promo, 0),
        rgba($c-promo, 1)
      );
    }

    &::after {
      right: 0;
      background-image: linear-gradient(
        to right,
        rgba($c-promo, 0),
        rgba($c-promo, 1)
      );
    }
  }
}

.promo__hero__label {
  color: $black;
}

.promo__hero__title {
  margin-top: 2rem;
  color: $white;
}

.promo__hero__subtitle {
  max-width: 48rem;
  margin-inline: auto;
  color: $white;
  font-size: 2.5rem;
}

.promo__countdown {
  margin: 3rem auto;

  @include mq(l) {
    margin: 3rem 0;
  }
}

.promo__intro__title {
  @extend %fw-normal;

  margin-top: $spacing * 3;
  margin-bottom: 3rem;
  text-align: center;

  @include mq(l) {
    margin-top: $spacing * 6;
    margin-bottom: 7rem;
  }
}

.promo__intro__cards {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 -3rem;

  @include mq(xl) {
    margin: 0;
    padding-bottom: 6rem;
  }
}

.promo__intro__cards__item {
  justify-content: flex-start;
}

.promo__cta {
  max-width: 45rem;
  margin: 0 auto;
  padding: 0 3rem;
  box-shadow: none;

  @include mq(l) {
    margin: 0;
  }

  @include mq(xl) {
    position: absolute;
    top: 13rem;
    left: 50%;
    max-width: 53rem;
    padding: 0 5rem;
  }
}

.promo__cta__copy {
  svg {
    width: 4rem;
    height: 4rem;
    margin-bottom: 1.5rem;

    @include mq(l) {
      width: 5.5rem;
      height: 5.5rem;
      margin-bottom: 2.5rem;
    }
  }
}

.promo__cta__title {
  padding-bottom: 2.5rem;
  font-family: $ff-alt;
  font-size: 2.2rem;
  line-height: 1.2;

  @include mq(l) {
    font-size: 3.4rem;
  }
}

.promo__cta__anchor {
  @extend %fw-bold;

  display: inline-flex;
  align-items: center;
  color: $c-blue-dark;
  line-height: 1;
  text-decoration: none;

  .icon {
    width: 3rem;
    height: 4rem;
    margin-right: $spacing;
    fill: $c-gray-darkest;
  }
}

.hero-visuals-left,
.hero-visuals-right {
  display: none;

  img {
    width: 100%;
  }

  @include mq(l) {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 10rem;
    height: 40rem;
  }
}

.hero-visuals-left {
  left: 0;
  width: 42.7rem;
  height: 47.1rem;
}

.hero-visuals-right {
  right: 0;
  width: 66.6rem;
  height: 39.7rem;
}

.hero-visuals-mobile {
  line-height: 0;
  text-align: center;

  @include mq(l) {
    display: none;
  }
}

.promo__vod {
  padding-top: 0;
  padding-bottom: 0;
}

.promo__secondary__title,
.promo__secondary__text {
  @extend %fw-normal;

  text-align: center;
}

.promo__secondary__title {
  margin-top: 0;
}

.promo__secondary__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -3rem;
}

.promo__secondary__cards__item {
  flex-basis: auto;
  max-width: 53rem;
}

.promo__secondary__stamp {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: $spacing;
}

.promo__secondary__condition {
  position: relative;
  color: $c-gray;
  font-size: 1.2rem;
  text-align: center;
}

// WILL MOVE INTO COMPONENTS FILE

.promo__perks {
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.promo__perks,
.promo__smartphones,
.promo__options {
  // prettier-ignore
  @include fluid(padding-top, (s: 40px, l: 60px));
}

.promo__options {
  // prettier-ignore
  @include fluid(padding-bottom, (s: 40px, l: 60px));
}

.promo__perks,
.promo__smartphones {
  // prettier-ignore
  @include fluid(padding-bottom,(s: 60px,l: 80px,));
}

.promo__group {
  position: relative;
  z-index: 1;

  &::after {
    position: absolute;
    z-index: -1;
    top: 75%;
    left: 0;
    transform: translate(-50%, -50%);
    content: '';
    display: block;
    width: 120vw;
    height: 120vw;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba($c-blue-mat, 1) 0%,
      rgba($c-violet, 0.1) 100%
    );
    opacity: 0.6;
    filter: blur(60px);
  }
}

.perks__items {
  display: flex;
  gap: $spacing * 1.5;
}

.perks__item {
  @extend %text-center;

  flex-grow: 1;
  padding: $spacing * 1.5;
  color: $white;
  background-color: rgba($white, 0.1);
}

.promo__perks {
  // prettier-ignore
  @include fluid(padding-top, (s: 40px, l: 60px));

  // prettier-ignore
  @include fluid(padding-bottom,(s: 60px,l: 80px,));
}

// .promo-incentive {
//   position: relative;
//   overflow: hidden;
// }

// .promo-incentive__content-outer {
//   // prettier-ignore
//   @include fluid(margin-top, (s: 60px, l: 120px));

//   // prettier-ignore
//   @include fluid(margin-bottom,(s: 60px,l: 120px,));

//   position: relative;
//   z-index: 1;
//   max-width: 65rem;
//   padding: 0 $spacing * 2;
//   color: $white;
//   border-radius: 20px;

//   &::before {
//     @include get-all-space;

//     z-index: -1;
//     content: '';
//     display: block;
//     background-color: rgba($white, 0.06);
//     backdrop-filter: blur(15px);
//   }

//   &::after {
//     position: absolute;
//     z-index: -2;
//     top: 0;
//     left: 0;
//     transform: translate(-25%, -50%);
//     content: '';
//     display: block;
//     width: 816.89px;
//     height: 1070.68px;
//     background: radial-gradient(
//       50% 50% at 50% 50%,
//       $c-blue-mat 0%,
//       rgba($c-midnight-darker, 0) 100%
//     );
//     opacity: 0.6;
//     filter: blur(60px);
//   }

//   @include mq(m) {
//     flex-basis: calc((100% - 3rem) / 2);
//   }

//   @include mq(xl) {
//     flex-basis: calc((100% - 9rem) / 4);
//   }
// }

// .promo-incentive__content__alreadyclient {
//   padding: $spacing * 2 0;
//   border-bottom: 1px solid $c-midnight-darker;
// }

// .promo-incentive__content__alreadyclient__title {
//   // prettier-ignore
//   @include fluid(font-size,(s: 36px,l: 42px,));

//   margin: 0;
// }

// .promo-incentive__content__alreadyclient__subtitle {
//   // prettier-ignore
//   @include fluid(font-size,(s: 26px,l: 32px,));

//   margin: 0;
// }

// .promo-incentive__content__alreadyclient__text {
//   // prettier-ignore
//   @include fluid(font-size,(s: 14px,l: 18px,));
// }

// .promo-incentive__content__alreadyclient__btn {
//   margin-top: $spacing;
// }

// .promo-incentive__content__entertain {
//   padding: $spacing * 2 0;
// }

// .promo-incentive__content__entertain__title {
//   margin-top: 0;
// }
