
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
























.panel-contact,
[class*='panel-contact--'] {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100vw;
  max-width: 70rem;
  height: 100%;
  min-height: 100vh;
  padding: 6rem 1.5rem;
  background: $c-light;
  padding-bottom: 60vh;
  @include mq(s) {
    padding: 6rem;
  }
}
