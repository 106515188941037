
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        




































































































.header-hardware,
[class*='header-hardware--'] {
  position: relative;
  width: 100%;
}

.header-hardware-inner {
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: $spacing * 3 1.5rem $spacing;
  color: $c-light;

  @include mq(xs) {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding-bottom: $spacing * 3;
  }

  @include mq(s) {
    padding: $spacing * 3;
  }
}

.header-hardware__speedline {
  position: absolute;
  // right: -4%;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 50%;
  max-height: 27rem;
  // transform: translateY(100%);

  ::v-deep {
    path {
      transform: translateY(80%);
    }
  }

  .gray & {
    fill: $c-gray;
  }

  .blue & {
    fill: $c-light-blue;
  }

  .paleblue & {
    fill: $c-blue-pale;
  }

  .cyan & {
    fill: $c-cyan-contrast;
  }

  .orange & {
    fill: $c-orange;
  }

  .burgundy & {
    fill: $c-burgundy;
  }

  .midnightblue & {
    fill: $c-blue-dark;
  }

  .pink & {
    fill: $c-pink-medium;
  }

  .lightpink & {
    fill: $c-pink-light;
  }

  .purple & {
    fill: $c-purple;
  }

  .gift-color-a & {
    fill: $c-gift-color-a;
  }

  .gift-color-b & {
    fill: $c-gift-color-b;
  }

  .gift-color-c & {
    fill: $c-gift-color-c;
  }
}

.header-hardware__background {
  display: block;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.header-hardware__title {
  @extend %fw-medium;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  width: 100%;
  max-width: 100%;
  margin-bottom: $spacing;
  font-family: $ff-alt;
  font-size: 1.5rem;

  .label--pink {
    margin-bottom: $spacing;
    font-size: 1.3rem;
    font-weight: 900;
    text-transform: none;
  }

  ::v-deep {
    img {
      max-width: 10rem;
      max-height: 5rem;
      margin-bottom: 2rem;
    }

    .hardware-specs__subtitle {
      margin-bottom: 1rem;
      font-size: 1.4rem;
      font-weight: 700;
      white-space: nowrap;

      @include mq('l') {
        font-size: 2rem;
      }
    }

    strong {
      margin-bottom: 2rem;
      font-weight: 900;
    }
  }

  @include mq(xs) {
    flex-shrink: 0;
    flex-basis: 50%;
    width: auto;
    margin-bottom: 0;
  }
}

.header-hardware__background-outer {
  @include get-all-space;

  overflow: hidden;
}

.header-hardware__picture {
  position: relative;
  width: 80%;
  margin: 0;

  ::v-deep img {
    width: 100%;
    height: auto;
    object-fit: contain;

    @include mq(xs) {
      @include image-fit(contain, right bottom);
    }

    @include mq(s) {
      transform: scale(1.25);
    }
  }

  @include mq(xs) {
    flex-basis: 50%;
    width: 100%;
  }
}

.header-hardware__cta {
  margin-top: $spacing;

  @include mq($until: 'm') {
    .hardware-specs-inner & {
      margin-bottom: $spacing * 5;
    }
  }
}

.header-hardware__counter {
  margin-top: $spacing;
}
