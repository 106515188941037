
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        




















.faq,
[class*='faq--'] {
  position: relative;
}

::v-deep {
  .faq-outer,
  .h-wrapper {
    padding: 0;
  }

  .faq__listing {
    width: 100%;
    margin-top: 0;
  }

  .faq-item {
    &.is-white {
      background-color: $c-light-gray;
    }
    + .faq__item {
      margin-top: 1rem;
    }
  }

  .faq-outer.is-light-gray {
    background-color: unset;
  }
}
