
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


































































.promo-options {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.promo-options__speedlines {
  @include get-all-space;
  @include center-xy;

  z-index: -1;

  .speedlines__item,
  [class*='speedlines__item--'] {
    @include mq($until: m) {
      opacity: 0;
    }
  }
}

.promo-options__title {
  @extend %text-center;

  margin: 0;
  color: $white;
}

.promo-options__subtitle {
  @extend %text-center;

  margin: 0;
  color: $white;

  @include mq($until: m) {
    margin-top: $spacing;
  }
}

.promo-options__cards {
  position: relative;
  width: 100%;
  margin-top: $spacing * 2;

  @include mq(l) {
    margin-top: $spacing * 4.5;
  }
}

.promo-options__card {
  width: 30rem;
  height: 100%;
  margin: 0;

  @include mq(m) {
    width: 35rem;
  }
}

::v-deep {
  .generic-slider__slides {
    &.is-disabled {
      justify-content: center;
    }
  }

  .generic-slider__slides,
  .flickity-viewport {
    .flickity-slider {
      display: flex;
      align-items: stretch;
      width: 100%;
      height: 100%;
      min-height: 100%;
      scroll-snap-type: x mandatory;
    }

    .promo-options__card {
      // height: 100%;
    }
  }
}
