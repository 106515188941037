
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        



































































































































// .promo-incentive {
//   position: relative;
//   z-index: 1;
//   display: inline-flex;
//   flex-direction: column;
//   align-items: center;
// }

.promo-incentive {
  overflow: hidden;
}

.promo-incentive-inner {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column-reverse;

  &::after {
    position: absolute;
    z-index: -2;
    top: 100%;
    left: 0;
    transform: translate(-25%, -50%);
    content: '';
    display: block;
    width: 816.89px;
    height: 1070.68px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      $c-blue-mat 0%,
      rgba($c-violet, 0.2) 100%
    );
    opacity: 0.6;
    filter: blur(60px);
  }

  @include mq(l) {
    flex-direction: row;
  }
}

.promo-incentive__speedlines {
  @include get-all-space;
  @include center-xy;

  z-index: -1;

  .speedlines__item,
  [class*='speedlines__item--'] {
    @include mq($until: m) {
      opacity: 0;
    }
  }
}

.promo-incentive__content-outer {
  // prettier-ignore
  @include fluid(margin-top, (s: 60px, l: 120px));

  // prettier-ignore
  @include fluid(margin-bottom,(s: 60px,l: 120px,));

  position: relative;
  z-index: 2;
  flex-shrink: 0;
  width: 100%;
  max-width: 65rem;
  padding: 0 $spacing * 2;
  color: $white;
  border-radius: 20px;

  &::before {
    @include get-all-space;

    z-index: -1;
    content: '';
    display: block;
    background-color: rgba($white, 0.06);
    backdrop-filter: blur(15px);
    border-radius: 20px;
  }
}

.promo-incentive__content__alreadyclient {
  padding: $spacing * 2 0;
  border-bottom: 1px solid $c-midnight-darker;
}

.promo-incentive__content__alreadyclient__title {
  // prettier-ignore
  @include fluid(font-size,(s: 36px,l: 42px,));

  margin: 0;
}

.promo-incentive__content__alreadyclient__subtitle {
  // prettier-ignore
  @include fluid(font-size,(s: 26px,l: 32px,));

  margin: 0;
}

.promo-incentive__content__alreadyclient__text {
  // prettier-ignore
  @include fluid(font-size,(s: 14px,l: 18px,));
}

.promo-incentive__content__alreadyclient__btn {
  margin-top: $spacing;
}

.promo-incentive__content__entertain {
  padding: $spacing * 2 0;
}

.promo-incentive__content__entertain__title {
  margin-top: 0;
}

.promo-incentive__bubble-one,
.promo-incentive__bubble-two,
.promo-incentive__bubble-three {
  position: absolute;
  display: block;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.promo-incentive__bubbles {
  position: relative;
  z-index: 1;
  width: 30rem;
  height: 32rem;
  margin: auto;
  transform: scale(0.6) translate(-30%, 30%);
  transform-origin: center center;

  @include mq(m) {
    right: 5rem;
    transform: scale(0.6) translateY(30%);
  }

  @include mq(l) {
    right: 10rem;
    transform: none;
  }

  .promo-incentive__heart-voo {
    fill: $c-pink-medium;
    position: absolute;
    top: 34rem;
    left: 34.6rem;
    transform: rotate(-11.8deg);
  }

  .promo-incentive__pinkheart-voo {
    fill: $c-pink-light;
    position: absolute;
    top: 9rem;
    left: -6.4rem;
    transform: rotate(10.2deg);
  }

  .promo-incentive__bubble-one {
    top: -18rem;
    left: -11rem;
    width: 32.2rem;
    height: 32.2rem;
  }

  .promo-incentive__bubble-two {
    top: -5.8rem;
    left: 13.72rem;
    width: 40.6rem;
    height: 40.6rem;
  }
}

.promo-incentive__heart-filled {
  fill: $c-cyan-contrast;
  position: absolute;
  top: 8rem;
  left: 55.3rem;
  transform: rotate(8.6deg);
}

.promo-incentive__heart-voo {
  fill: $c-pink-medium;
  position: absolute;
  top: 8.4rem;
  left: 0.4rem;
  transform: rotate(5.8deg);
}

.promo-incentive__bubble-three {
  top: 22rem;
  left: -4rem;
  width: 20.6rem;
  height: 20.6rem;
}
